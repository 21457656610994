<template>
  <a-modal
    :title="title"
    :visible="open"
    :confirm-loading="uploading"
    @cancel="importExcelHandleCancel"
    @ok="importExcelHandleChange"
  >
    <a-spin tip="上传中..." :spinning="uploading">
      <!-- <a-form-model ref="form">
        <a-row :gutter="48">
          <a-col :md="12" :sm="24">
            <a-form-model-item label="起始年">
              <a-input v-model="yearStart" type="number" placeholder="请输入"/>
            </a-form-model-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-model-item label="结尾年">
              <a-input v-model="yearEnd" type="number" placeholder="请输入"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model> -->
      <a-upload-dragger
        name="file"
        accept=".xlsx, .xls"
        :file-list="fileList"
        :multiple="false"
        :remove="handleRemove"
        :before-upload="beforeUpload"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
          请将文件拖拽到此处上传❥(^_-)
        </p>
        <p class="ant-upload-hint">
          支持单个上传，也可以点击后选择文件上传
        </p>
      </a-upload-dragger>

      <p class="p">导入模版<span @click="importTemplate">下载</span></p>
    </a-spin>
  </a-modal>
</template>

<script>

import { importData } from '@/api/valve/device'

export default {
  name: 'ImportExcel',
  props: {
  },
  components: {
  },
  data () {
    return {
      title: '数据导入',
      open: false,
      uploadStatus: '',
      fileList: [],
      // 是否禁用上传
      uploading: false,
      updateSupport: 0,
      yearStart: null,
      yearEnd: null,
      value: []
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 导入excel窗体关闭 */
    importExcelHandleCancel (e) {
      this.open = false
      this.fileList = []
      // 关闭后刷新列表
      this.$emit('ok')
    },
    /** 下载模板操作 */
    importTemplate () {
      this.download('valve/valve-device/export/tem', {
        ...this.queryParams
      }, `设备导入模版_${new Date().getTime()}.xlsx`)
    },
    /** 导入excel窗体开启 */
    importExcelHandleOpen (e) {
      this.open = true
    },
    beforeUpload (file) {
      this.fileList = [file]
      return false
    },
    /** 导入excel */
    importExcelHandleChange () {
      // if (!this.yearStart || !this.yearEnd) {
      //   this.$message.warning(
      //     '请选择起始年/结尾年',
      //     3
      //   )
      //   return false
      // }
      this.uploading = true
      const { fileList } = this
      const formData = new FormData()
      formData.append('file', fileList[0])
      // formData.append('yearStart', this.yearStart)
      // formData.append('yearEnd', this.yearEnd)
      // formData.append('updateSupport', this.updateSupport)
      importData(formData).then(response => {
        if (response.code == 1 || response.code == 20001) {
          this.fileList = []
          this.$message.success(response.message)
          this.open = false
          this.$emit('ok')
        } else {
          this.$message.error(response.message)
        }
      }).finally(() => {
        this.uploading = false
      })
    },
    handleCheckedUpdateSupport () {
      this.updateSupport = this.updateSupport === 0 ? 1 : 0
    },
    handleRemove () {
      this.fileList = []
      this.uploading = false
    }
  }
}
</script>
<style lang="less" scoped>
  .p{
    padding: 10px;
    span{
      color: #40a9ff;
      cursor: pointer;
    }
  }
</style>
